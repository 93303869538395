<template>
  <a-form ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
    <a-form-item ref="title" label="菜单名称" name="title">
      <a-input v-model:value="form.title" />
    </a-form-item>
    <a-form-item ref="hidden" label="是否隐藏" name="hidden">
      <a-switch :checkedValue="1" :unCheckedValue="0" checked-children="是" un-checked-children="否" v-model:checked="form.hidden" />
    </a-form-item>
    <a-form-item ref="type" label="菜单类型" name="type">
      <a-radio-group v-model:value="form.type" button-style="solid">
        <a-radio-button :value="1">
          左侧菜单
        </a-radio-button>
        <a-radio-button :value="2">
          页内按钮
        </a-radio-button>
      </a-radio-group>
    </a-form-item>
    <a-form-item ref="icon" label="图标 " name='icon'>
      <a-input v-model:value="form.icon" placeholder="注意:请不要随便填写">
      </a-input>
    </a-form-item>
    <a-form-item ref="admin_path" label="后端类库" name='admin_path'>
      <a-input v-model:value="form.admin_path" />
    </a-form-item>
    <a-form-item ref="auth" label="权限标识" name='auth'>
      <a-input v-model:value="form.auth" />
    </a-form-item>
    <a-form-item ref="key" label="前端路由" name='key'>
      <a-input v-model:value="form.key" placeholder='该属性控制结构的唯一性请确保填入信息唯一' />
    </a-form-item>
    <a-form-item ref="component" label="前端组件" name='component'>
      <a-input v-model:value="form.component" />
    </a-form-item>
    <a-form-item ref="sort" label="菜单排序" name='sort'>
      <a-input v-model:value="form.sort" type='number' />
    </a-form-item>
    <a-form-item :wrapper-col="{ span: 10, offset: 2 }">
      <span class='mr15'>
        <a-button type="primary" @click="onSubmit">
          保存
        </a-button>
      </span>
     <!-- <a-button @click="onReset">
        重置
      </a-button>-->
    </a-form-item>
  </a-form>
</template>
<script>
import {
  getCurrentInstance,
  inject,
  reactive,
  ref,
  toRefs,
  watch,
  watchEffect,
} from "vue";
// 初始化默认筛选项数值
let defForm = {
  title: "",
  hidden: false,
  type: 1,
  key: "",
  icon: "",
  sort: null,
  component: "",
  admin_path: "",
  auth: "",
};
export default {
  name: "myfrom",
  props: {
    selectedNodes: {},
  },
  setup(props, { emit }) {
    const _lodash = inject("_lodash");
    const state = reactive({
      labelCol: { span: 4 },
      wrapperCol: { span: 12 },
      dataSource: [],
      rules: {
        type: [
          {
            required: true,
            message: "请选择菜单类型",
          },
        ],
        sort: [
          {
            required: true,
            message: "排序不能为空",
          },
        ],
        title: [
          {
            required: true,
            message: "菜单名称",
          },
        ],
      },
    });
    const form = ref({ ...defForm });
    const ruleForm = ref();
    const $message = inject("$message");
    watchEffect(() => {
      var selectedNodes = props.selectedNodes || {};
      if (!_lodash.isEmpty(selectedNodes)) {
        form.value = selectedNodes;
      } else {
        form.value = defForm;
      }
    });
    // 重置 
    const onReset=()=>{
       var selectedNodes = props.selectedNodes || {};
      if (!_lodash.isEmpty(selectedNodes)) {
        form.value = selectedNodes;
        console.log(form.value)
      } else {
        form.value = defForm;
      }
    }
    // 提交数据
    const onSubmit = function () {
      ruleForm.value
        .validate()
        .then((res) => {
          emit("submit", form.value);
          if (!form.value.id) {
            ruleForm.value.resetFields();
          }
        })
        .catch(() => {
          $message.error("请完善表单");
        });
    };
    return {
      form,
      ruleForm,
      ...toRefs(state),
      onSubmit,
      onReset
    };
  },
};
</script>